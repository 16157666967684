import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const HeaderLogo = () => (
  <StaticImage
    src="../../images/ttb-logo.png"
    placeholder="blurred"
    loading="eager"
    layout="constrained"
    style={{ width: "100%" }}
    alt=""
  />
)

export default HeaderLogo
