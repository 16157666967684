import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Fruits = () => (
  <StaticImage
    src="../../images/fruits_mobile.jpg"
    placeholder="blurred"
    loading="eager"
    layout="constrained"
    style={{ width: "100%", height: "100%" }}
    alt=""
  />
)

export default Fruits
