import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Circles = () => (
  <StaticImage
    src="../../images/circles.png"
    placeholder="blurred"
    loading="eager"
    layout="constrained"
    style={{ height: "100%" }}
    alt=""
  />
)

export default Circles
