import React, { useState, useEffect } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { parsePath } from "../utils/parse"

const MenuLink = React.forwardRef(({ path }, ref) => {
  const [isActive, setIsActive] = useState(false)
  useEffect(() => {
    if (typeof window !== "undefined") {
      console.log(path)
      console.log(window.location.pathname)
      setIsActive(window.location.pathname === path)
    }
  }, [path])

  const copy = path === "/" ? "HOME" : parsePath(path)

  return (
    <StyledLink
      to={path}
      style={{
        "--color": isActive ? "brown" : "black",
        "--font-weight": isActive
          ? "var(--font-weight-bold)"
          : "var(--font-weight-normal)",
      }}
      ref={ref}
      role="menuitem"
    >
      {copy}
    </StyledLink>
  )
})

const StyledLink = styled(Link)`
  width: 100%;
  color: var(--color);
  text-decoration: none;
  font-size: 2rem;
  font-weight: var(--font-weight);

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  height: 8rem;
  cursor: pointer;
`

MenuLink.propTypes = {
  path: PropTypes.string.isRequired,
}

export default MenuLink
