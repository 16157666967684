import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const FBIcon = props => (
  <StaticImage
    src="../../images/fb.png"
    placeholder="blurred"
    loading="eager"
    layout="constrained"
    style={{
      width: 55,
      HEIGHT: 55,
    }}
  />
)

export default FBIcon
