import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import Background from "./backgrounds/background"

const Section = React.forwardRef(
  ({ background, className, children, color, ...props }, ref) => {
    if (background) {
      return (
        <StyledSection
          as={Background}
          background={background}
          ref={ref}
          style={{
            "--background-color": `var(--${color})`,
          }}
          {...props}
        >
          {children}
        </StyledSection>
      )
    }

    return (
      <StyledSection
      ref={ref}
      style={{
        "--background-color": `var(--${color})`,
      }}
      {...props}
      >
        {children}
      </StyledSection>
    )
  }
)

Section.propTypes = {
  background: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
}

Section.defaultProps = {
  background: false,
  color: "pri-light",
}

const StyledSection = styled.section`
  padding: var(--vertical-spacing) var(--horizontal-spacing);
  background-color: var(--background-color);
`

export default Section
