import { createGlobalStyle } from "styled-components"

export default createGlobalStyle`
  /* Box sizing rules */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /* Remove default margin */
  body,
  h1,
  h2,
  h3,
  h4,
  p,
  figure,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  /* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
  ul[role='list'],
  ol[role='list'] {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  /* Set core root defaults */
  html:focus-within {
    scroll-behavior: smooth;
  }

  /* Set core body defaults */
  body {
    min-height: 100vh;
    text-rendering: optimizeLegibility;
    line-height: 1.5;
    font-family: Montserrat, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  }

  /* A elements that don't have a class get default styles */
  a:not([class]) {
    text-decoration-skip-ink: auto;
  }

  /* Make images easier to work with */
  img,
  picture {
    max-width: 100%;
    display: block;
  }

  /* Inherit fonts for inputs and buttons */
  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  p {
    margin-bottom: 24px;
  }

  .anchor {
    display: block;
    text-decoration: none;
    width: fit-content;
  }
  /* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
  @media (prefers-reduced-motion: reduce) {
    html:focus-within {
    scroll-behavior: auto;
    }
    
    *,
    *::before,
    *::after {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }

  html {
    /* ----------
      COLOR PALETTE
    ---------- */
    --pri-light: hsl(27deg, 100%, 98%);
    --pri-med: hsl(28deg, 60%, 95%);
    --pri-dark: hsl(30deg, 74%, 91%);
    --sec-light: hsl(136deg, 25%, 85%);
    --sec-dark: hsl(134deg, 16%, 64%);
    --carrot: hsl(33deg, 95%, 54%);
    --grapefruit: hsl(15deg, 54%, 55%);
    --beet: hsl(314deg, 68%, 68%);
    --blueberry: hsl(210deg, 56%, 49%);
    /* ----------
      TYPOGRAPHY RULES
    ---------- */
    --font-weight-bold: 700;
    --font-weight-semi-bold: 600;
    --font-weight-medium: 500;
    --font-weight-regular: 400;
    --font-weight-light: 300;
    --font-family-primary: "Tenor Sans", "Fahkwang Light", "CMU Sans Serif Medium", "Nova Normal", "Arsenal Regular", "Gothic A1 Regular", "sans‑serif";
    --font-family-secondary: "Montserrat", "DejaVu Sans", "Verdana", "sans‑serif";
    /* ----------
      RULES FOR SECTION SPACING
    ---------- */
    --vertical-spacing: 64px;
    --horizontal-spacing: 24px;

    @media (min-width: 700px) {
      --vertical-spacing: 92px;
      --horizontal-spacing: 48px;
    }

    @media (min-width: 1233px) {
      --vertical-spacing: 120px;
      --horizontal-spacing: 80px;
    }
  }
`
