import React from "react"
import styled from "styled-components"

import Chia from "./chia"
import Circles from "./circles"
import Cottage from "./cottage"
import Fruits from "./fruits"
import Parallax from "./parallax"

const imageSelector = {
  chia: Chia,
  circles: Circles,
  cottage: Cottage,
  fruits: Fruits,
  parallax: Parallax,
}

const Background = ({ background, children, ...props }) => {
  const BackgroundImage = imageSelector[background]

  return (
    <BackgroundWrapper {...props}>
      {background && (
        <ImageWrapper>
          <BackgroundImage />
        </ImageWrapper>
      )}
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </BackgroundWrapper>
  )
}

const BackgroundWrapper = styled.div`
  position: relative;
  max-width: 100vw;
  width: 100vw;
  height: 100%;
  isolation: isolate;
  overflow: hidden;
`

const ImageWrapper = styled.div`
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 100%;
`

const ChildrenWrapper = styled.div`
  position: relative;
  height: 100%;
  z-index: 1;
`

export default Background
