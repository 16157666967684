import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { parsePath } from "../utils/parse"

const Navlink = ({ path }) => {
  return (
    <StyledLink to={path}>
      <Line>{parsePath(path)}</Line>
    </StyledLink>
  )
}

Navlink.propTypes = {
  path: PropTypes.string.isRequired,
}

const StyledLink = styled(Link)`
  color: black;
  height: 1.5em;
  text-decoration: none;
  padding: 0 0.5em;
  cursor: pointer;

  @media (max-width: 500px) {
    display: none;
  }
`

const Line = styled.div`
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &:hover {
    /* height: calc(1em + 1px); */
    border-bottom: 1px solid black;
  }
`

export default Navlink
