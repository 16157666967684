import React from "react"
import styled from "styled-components"

import MenuLink from "./menuLink"

const MenuLinks = ({ routes, toggleOpen }) => {
  return (
    <RootWrapper>
      <MenuLink path="/" toggleOpen={toggleOpen} />
      {routes.map(({ path }) => (
        <MenuLink key={path} path={path} toggleOpen={toggleOpen} />
      ))}
    </RootWrapper>
  )
}

const RootWrapper = styled.div`
  position: fixed;

  top: 50%;
  left: 50%;
  height: fit-content;
  width: 100%;
  transform: translate(-50%, -50%);
  z-index: 2;
`

export default MenuLinks
