import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const FooterLogo = () => (
  <StaticImage
    src="../../images/footer-logo.png"
    placeholder="blurred"
    loading="eager"
    layout="constrained"
    style={{
      width: "100%",
      maxWidth: "150px",
    }}
  />
)

export default FooterLogo
