import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const HeaderLogo = ({ children, ...props }) => {
  return (
    <div {...props}>
      {children}
      <StaticImage
        src="../images/menu.png"
        placeholder="blurred"
        loading="eager"
        layout="constrained"
        style={{ width: "100%", maxWidth: "30px" }}
        alt=""
      />
    </div>
  )
}

export default HeaderLogo
