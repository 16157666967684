import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import * as VisuallyHidden from "@radix-ui/react-visually-hidden"

import HeaderLogo from "./images/headerLogo"
import Navigation from "./navigation"

const Header = () => (
  <StyledHeader>
    <FlexWrapper>
      <Link to="/">
        <VisuallyHidden.Root>
          Home page
        </VisuallyHidden.Root>
        <LogoWrapper>
          <HeaderLogo />
        </LogoWrapper>
      </Link>
      <Navigation />
    </FlexWrapper>
  </StyledHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

const StyledHeader = styled.header`
  width: 100%;
  height: 37px;
  position: fixed;
  top: 0;
  padding: 2.5rem 1rem;
  z-index: 1;
  background-color: var(--pri-light);
`

const FlexWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LogoWrapper = styled.div`
  position: absolute;
  width: 260px;
  top: 50%;
  margin-top: -48px;

  @media (min-width: 700px) {
    width: 260px;
    margin-top: -45px;
  }

  @media (max-width: 365px) {
    display: none;
  }
`

export default Header
