import React from "react"
import styled from "styled-components"
import * as VisuallyHidden from "@radix-ui/react-visually-hidden"

import Section from "./section"
import MaxWidthWrapper from "./maxWidthWrapper"
import FooterLogo from "./images/footerLogo"
import Facebook from "./images/iconFB"
import Insta from "./images/iconInsta"
import Aclm from "./images/logoACLM"
import Aap from "./images/logoAAP"
import Heading from "./typography/heading"

function Footer() {
  return (
    <StyledSection as="footer" color="beet">
      <div style={{ display: "flex", justifyContent: "center" }}>
        <FlexWrapper width={1100}>
          <FooterLogo />
          <FlexWrapper>
            <Stack>
              <div>
                <Heading tag="h3">ADDRESS</Heading>
                <p>
                  51 Johnny Mercer Blvd. <br />
                  Building A-2 Suite A<br />
                  Savannah, GA 31400
                </p>
              </div>
              <div>
                <Heading tag="h3">PHONE</Heading>
                <p>(912) 777-6830</p>
              </div>
              <div>
                <Heading tag="h3">EMAIL</Heading>
                <p>thedoctorbeet@gmail.com</p>
              </div>
            </Stack>
            <Stack>
              <div>
                <Heading tag="h3">HOURS</Heading>
                <p>Monday - Friday, 9am - 4pm</p>
              </div>
              <div>
                <Heading tag="h3">FIND US ON SOCIAL!</Heading>
                <FlexWrapper>
                  <a href="https://instagram.com/thedoctorbeet?igshid=1e95ag0yyg2o4">
                    <VisuallyHidden.Root>
                      Go to The Tiny Beet Instagram page
                    </VisuallyHidden.Root>
                    <Insta alt="Instagram logo" />
                  </a>
                  <a href="https://www.facebook.com/thedoctorbeet/">
                    <VisuallyHidden.Root>
                      Go to The Tiny Beet Facebook page
                    </VisuallyHidden.Root>
                    <Facebook alt="Facebook logo" />
                  </a>
                </FlexWrapper>
              </div>
            </Stack>
            <Stack style={{ gap: 16 }}>
              <Heading tag="h3">BOARD CERTIFICATIONS</Heading>
              <Aap />
              <Aclm />
            </Stack>
          </FlexWrapper>
          <CopyrightLine>
            &copy; {new Date().getFullYear()} The Tiny Beet. All Rights
            Reserved.
          </CopyrightLine>
        </FlexWrapper>
      </div>
    </StyledSection>
  )
}

const StyledSection = styled(Section)`
  padding: var(--vertical-spacing) var(--horizontal-spacing);
  background-color: var(--background-color);
  padding-bottom: 16px;
  background-color: var(--beet);
`

const FlexWrapper = styled(MaxWidthWrapper)`
  max-width: 837px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 24px;
`

const Stack = styled.div`
  flex: 1 1 263px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;

  & p {
    margin-bottom: 0;
  }
`

const CopyrightLine = styled.p`
  margin: 0;
  font-size: 0.85rem;
`

export default Footer
