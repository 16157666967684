import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Menu from "./menu"
import Navbar from "./navbar"

const Navigation = () => {
  const data = useStaticQuery(
    graphql`
      query MyQuery {
        allSitePage(
          filter: {
            path: { nin: ["/dev-404-page/", "/", "/404/", "/404.html"] }
          }
        ) {
          nodes {
            path
          }
        }
      }
    `
  )

  // escape if no data retrieved
  if (!data?.allSitePage?.nodes) return null

  return (
    <>
      <Menu routes={data.allSitePage.nodes} />
      <Navbar routes={data.allSitePage.nodes} />
    </>
  )
}

export default Navigation
