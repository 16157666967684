import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Cottage = () => (
  <StaticImage
    src="../../images/cottage.jpg"
    placeholder="blurred"
    loading="eager"
    layout="constrained"
    alt=""
  />
)

export default Cottage
