import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import * as DialogPrimitive from "@radix-ui/react-dialog"
import * as VisuallyHidden from "@radix-ui/react-visually-hidden"

import MenuButton from "./menuButton"
import MenuLinks from "./links"
import CloseIcon from "./images/iconClose"

import "../styles/custom-properties.css"

const Menu = ({ routes }) => {
  return (
    <>
      <DialogPrimitive.Root>
        <DialogPrimitive.Trigger asChild>
          <StyledMenuButton>
            <VisuallyHidden.Root>Open menu</VisuallyHidden.Root>
          </StyledMenuButton>
        </DialogPrimitive.Trigger>
        <DialogContent>
          <VisuallyHidden.Root asChild>
            <DialogPrimitive.Title>Navigation Menu</DialogPrimitive.Title>
          </VisuallyHidden.Root>
          <VisuallyHidden.Root asChild>
            <DialogPrimitive.Description>
              Links to rest of website
            </DialogPrimitive.Description>
          </VisuallyHidden.Root>
          <DialogPrimitive.Close asChild>
            <StyledCloseButton>
              <VisuallyHidden.Root>Close menu</VisuallyHidden.Root>
            </StyledCloseButton>
          </DialogPrimitive.Close>
          <MenuLinks routes={routes} />
        </DialogContent>
      </DialogPrimitive.Root>
    </>
  )
}

const StyledOverlay = styled(DialogPrimitive.Overlay)`
  background-color: #95b29cd2;
  backdrop-filter: blur(5px);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 1;
`

const StyledContent = styled(DialogPrimitive.Content)`
  background-color: var(--sec-dark);
  position: fixed;
  width: 100%;
  z-index: 2;
`

const DialogContent = ({ children, ...props }) => (
  <DialogPrimitive.Portal>
    <StyledOverlay />
    <StyledContent {...props}>{children}</StyledContent>
  </DialogPrimitive.Portal>
)

const StyledMenuButton = styled(MenuButton)`
  padding: 7px;
  @media (min-width: 500px) {
    display: none;
  }
`

const StyledCloseButton = styled(CloseIcon)`
  all: unset;
  position: fixed;
  padding: 14.5px;
  top: 16px;
  right: 16px;
`

Menu.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
}

export default Menu
