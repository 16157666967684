import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

export default function Heading({ tag, ...props }) {
  switch (tag) {
    case "h1":
      return <BasePrimary {...props} />
    case "h2":
      return <BasePrimary as="h2" {...props} />
    case "h3":
      return <H3 as="h3" {...props} />
    // case "h4":
    //   return <H4 as="h4" {...props} />
    // case "h5":
    //   return <H5 as="h5" {...props} />
    default:
      throw new Error("Heading Component Error: Missing tag prop")
  }
}

const BaseStyles = styled.h1`
  padding: 0;
  margin-bottom: 24px;
  color: inherit;
  line-height: 1.1;
`

const BasePrimary = styled(BaseStyles)`
  font-family: Tenor Sans, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: 400;
  --type-fontsize-min: 2.5rem;
  --type-fontsize-max: 3.2rem;
  --type-fontsize-value: 4.1vw + 1rem;
  font-size: clamp(
    var(--type-fontsize-min),
    var(--type-fontsize-value),
    var(--type-fontsize-max)
  );
`

// const BaseSecondary = styled(BaseStyles)`
//   font-family: Montserrat, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
//     Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
//   font-size: 1.5rem;
//   font-weight: 700;
// `

const H3 = styled(BasePrimary)`
  margin-bottom: 8px;
  font-size: ${20 / 16}rem;
  font-weight: 700;
`

Heading.propTypes = {
  tag: PropTypes.string.isRequired,
}
